import React from 'react';
import HeroSection from '../Components/HeroSection';
import { FoldingLogos } from '../Components/FoldingLogos';
import { BouncyCardsFeatures } from '../Components/BouncyCardsFeatures';
import CollapseCardFeatures from '../Components/CollapseCardFeatures';
import { Helmet } from "react-helmet";

const Portfolio = () => {
  // Define the title and description variables
  const title = 'Turning Ideas into Impactful Digital Solutions';

  const description = (
    <>
      Welcome to a showcase of projects where vision meets execution. Each case study highlights
      my dedication to blending design, development, and strategy, delivering results that empower
      brands to thrive online.
    </>
  );

  return (
    <div>
    <Helmet>
    <title>Portfolio | Projects by Plambech Development</title>
    <meta name="description" content="Explore the portfolio of freelance developer Rasmus Plambech, showcasing custom WordPress and PHP projects delivered over the past 10 years." /> 

      {/* Canonical Tag */}
      <link rel="canonical" href="https://plambech.dev/portfolio" />

      {/* Open Graph Tags */}
      <meta property="og:title" content="Portfolio | Projects by Plambech Development" />
      <meta property="og:description" content="Explore the portfolio of freelance developer Rasmus Plambech, showcasing custom WordPress and PHP projects delivered over the past 10 years." />
      <meta property="og:url" content="https://plambech.dev/" />

      {/* Twitter Card Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Portfolio | Projects by Plambech Development" />
      <meta name="twitter:description" content="Explore the portfolio of freelance developer Rasmus Plambech, showcasing custom WordPress and PHP projects delivered over the past 10 years." />

      {/* Additional Meta Tags */}
      <meta name="robots" content="index, follow" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta charset="UTF-8" />
      <meta name="author" content="Rasmus Plambech" />
    </Helmet>

      {/* Pass the variables as props to HeroSection */}
      <HeroSection
        title={title}
        description={description}
        buttonText="See what I can do for you!"
        buttonLink="/services"
      />
      <BouncyCardsFeatures />
      <CollapseCardFeatures />
      <FoldingLogos />
    </div>
  );
};

export default Portfolio;
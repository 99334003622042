import React from "react";
import NeuFollowButton from '../Components/NeuFollowButton';
import { Helmet } from "react-helmet";

const NotFoundPage = () => {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
        <title>404 Not Found - Plambech Development</title>
      </Helmet>
      <section className="bg-white dark:bg-darkBlue py-44">
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
          <div className="mx-auto max-w-screen-sm text-center">
            <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-white">
              404
            </h1>
            <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">
              Something's missing.
            </p>
            <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
              Sorry, we can't find that page. You'll find lots to explore on the
              home page.
            </p>
            <div className="flex justify-center">
              <NeuFollowButton text="Go to homepage" link="/" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NotFoundPage;
// PortfolioCard.js

import React from "react";
import { motion } from "framer-motion";

const PortfolioCard = ({ title, description, imgSrc, tech, projectLinks, bgColor, openModal }) => {
  const handleCardClick = () => {
    openModal({ title, description, imgSrc, tech, projectLinks });
  };

  return (
    <motion.div
      whileHover={{ scale: 0.95, rotate: "-1deg" }}
      onClick={handleCardClick}
      className={`group relative min-h-[300px] cursor-pointer overflow-hidden rounded-2xl bg-slate-100 p-8 col-span-12 md:col-span-4`}
    >
      {/* Display the title using CardTitle */}
      <CardTitle>{title}</CardTitle>
      
      {/* Display the image as the card background */}
      <div
        className={`absolute bottom-0 left-4 right-4 top-20 translate-y-8 inset-0 bg-cover bg-center rounded-t-2xl transition-transform duration-[250ms] group-hover:translate-y-2 group-hover:rotate-[2deg]`}

        style={{ backgroundImage: `url(${imgSrc})` }}
      >
        <img
          src={imgSrc}
          alt={`${title} feature preview`}
          className="w-full h-full object-cover rounded-t-2xl opacity-75 group-hover:opacity-100 transition-opacity"
        />
      </div>
    </motion.div>
  );
};

const CardTitle = ({ children }) => {
  return (
    <h3 className="mx-auto text-center text-3xl font-semibold mb-4">{children}</h3>
  );
};

export default PortfolioCard;
import React from 'react';
import { FoldingLogos } from '../Components/FoldingLogos';
import Timeline from '../Components/Timeline';
import HeroSection from '../Components/AboutHero';

import { Helmet } from "react-helmet";

const About = () => {
  return (
    <div>
    <Helmet>
    <title>About Rasmus Plambech | Experienced Freelance Developer</title>
    <meta name="description" content="Rasmus Plambech is a seasoned freelance developer with over 10 years of experience in WordPress, PHP, JavaScript, and SEO—crafting high-performance websites that drive business growth." />
      {/* Canonical Tag */}
      <link rel="canonical" href="https://plambech.dev/about" />

      {/* Open Graph Tags */}
      <meta property="og:title" content="About Rasmus Plambech | Experienced Freelance Developer" />
      <meta property="og:description" content="Rasmus Plambech is a seasoned freelance developer with over 10 years of experience in WordPress, PHP, JavaScript, and SEO—crafting high-performance websites that drive business growth." />
      <meta property="og:url" content="https://plambech.dev/about" />

      {/* Twitter Card Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="About Rasmus Plambech | Experienced Freelance Developer" />
      <meta name="twitter:description" content="Rasmus Plambech is a seasoned freelance developer with over 10 years of experience in WordPress, PHP, JavaScript, and SEO—crafting high-performance websites that drive business growth." />

      {/* Additional Meta Tags */}
      <meta name="robots" content="index, follow" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta charset="UTF-8" />
      <meta name="author" content="Rasmus Plambech" />
    </Helmet>

      <HeroSection />
      <Timeline />
      <FoldingLogos/>
    </div>
  );
};

export default About;
import React from "react";
import { Helmet } from "react-helmet";


const PrivacyPolicy = () => {
  return (

    <div className="bg-gray-100">
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
      <div className="container mx-auto px-4 py-20">
        <h1 className="text-3xl font-bold mb-4">Cookie and Privacy Policy</h1>

        <h2 className="text-2xl font-bold mb-2">Introduction</h2>
        <p className="mb-4">
          When you visit our website, information about you is collected to customize and enhance our content, as well as to increase the value of the advertisements shown on the page. If you do not wish for information to be collected, you should delete your cookies (see instructions) and refrain from further use of the website. Below, we have detailed what information is collected, its purpose, and which third parties have access to it.
        </p>

        <h2 className="text-2xl font-bold mb-2">Cookies</h2>
        <p className="mb-4">
          This website uses “cookies,” which are text files stored on your computer or mobile device with the purpose of recognizing it, saving settings, performing statistics, and targeting advertisements. Cookies cannot contain harmful code like viruses.
        </p>
        <p className="mb-4">
          It is possible to delete or block cookies. See instructions: <a href="http://minecookies.org/cookiehandtering" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">http://minecookies.org/cookiehandtering</a>
        </p>
        <p className="mb-4">
          If you delete or block cookies, you may experience less relevant ads and more frequent appearances. Additionally, the website may not function optimally, and some content may be inaccessible.
        </p>
        <p className="mb-4">
          The website may contain cookies from third parties, which can include:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>Google</li>
          <li>YouTube</li>
          <li>Vimeo</li>
          <li>Facebook</li>
          <li>LinkedIn</li>
        </ul>

        <h2 className="text-2xl font-bold mb-2">Personal Information</h2>
        
        <h3 className="text-xl font-bold mb-2">General</h3>
        <p className="mb-4">
          Personal information is any information that can be attributed to you. When you use our website, we collect and process a variety of information. This happens, for example, during regular interaction with content, if you sign up for our newsletter, participate in surveys or competitions, register as a user, use other services, or make purchases via the website.
        </p>
        <p className="mb-4">
          Typically, we collect and process the following types of information: a unique ID, technical details about your device, IP address, geographic location, and which pages you click on (interests). Additionally, with your explicit consent, we may process your name, phone number, email, address, and payment details, typically in connection with creating a login or completing a purchase.
        </p>

        <h3 className="text-xl font-bold mb-2">Security</h3>
        <p className="mb-4">
          We have taken technical and organizational measures to prevent your information from being accidentally or illegally deleted, disclosed, lost, degraded, accessed by unauthorized persons, misused, or otherwise processed in violation of the law.
        </p>

        <h3 className="text-xl font-bold mb-2">Purpose</h3>
        <p className="mb-4">
          The information is used to identify you as a user and to show you advertisements that are most likely relevant to you, register your purchases and payments, and to provide the services you have requested, such as sending a newsletter. Additionally, we use the information to optimize our services and content.
        </p>

        <h3 className="text-xl font-bold mb-2">Retention Period</h3>
        <p className="mb-4">
          Information is stored for the period permitted by law, and we delete it when it is no longer necessary. The period depends on the nature of the information and the reason for storage. Therefore, it is not possible to specify a general timeframe for when information is deleted.
        </p>

        <h3 className="text-xl font-bold mb-2">Disclosure of Information</h3>
        <p className="mb-4">
          Data about your usage of the website, advertisements you receive and possibly click on, geographic location, gender, and age segment, among other information, is shared with third parties to the extent that this information is available. You can see which third parties are involved in the "Cookies" section above. The information is used for targeted advertising.
        </p>
        <p className="mb-4">
          Additionally, we use several third parties for data storage and processing. These parties only process information on our behalf and are not permitted to use it for their own purposes.
        </p>
        <p className="mb-4">
          Personal information such as your name and email will only be shared if you consent to it. We only use data processors in the EU or in countries that can provide your information with adequate protection.
        </p>

        <h3 className="text-xl font-bold mb-2">Your Rights</h3>
        <p className="mb-4">
          You have the right to be informed about the personal information we process about you. You can object at any time to the use of your information. You can also revoke your consent to us processing information about you. If the data we have about you is incorrect, you have the right to have it corrected or deleted. Please contact us at <a href="mailto:info@plambech.dev" className="text-blue-500 underline">info@plambech.dev</a> for inquiries. If you wish to complain about our handling of your personal information, you may also contact the Data Protection Agency (Datatilsynet).
        </p>

        <h3 className="text-xl font-bold mb-2">Publisher</h3>
        <p className="mb-4">
          This website is owned and published by:
          <br />
          Plambech Development<br />
          Email: <a href="mailto:info@plambech.dev" className="text-blue-500 underline">info@plambech.dev</a><br />
          CVR: 44912228
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
// HeroSection.jsx
import React from 'react';
import HoverGrid from './HoverGrid';
import { Reveal } from './Reveal';
import NeuFollowButton from './NeuFollowButton';
import { CountUpStats } from './CountUpStats';
import ShiftingContactForm from './ContactForm';

function HeroSection({ title, description, buttonText, buttonLink  }) {
  return (
    <div className="relative min-h-screen h-full bg-neutral-950 flex justify-center content-center flex-wrap">
      {/* Canvas Background */}
      <HoverGrid />

      {/* Content Overlay */}
      <div className="relative pointer-events-none h-full inset-0 flex flex-col items-start justify-center pt-32 md:pt-24 pb-8 px-8 mx-auto w-full max-w-7xl">
        <div className="grid max-w-screen-xl py-8 mx-auto lg:gap-8 xl:gap-10 lg:py-16 lg:grid-cols-12">
          <div className="mr-auto place-self-center lg:col-span-7">
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold leading-none md:text-5xl xl:text-5xl text-white">
              {title}
            </h1>
            <p className="max-w-2xl mb-6 font-light text-gray-400 lg:mb-8 md:text-lg lg:text-xl">
              {description}
            </p>
            {buttonText && buttonLink && (
              <NeuFollowButton text={buttonText} link={buttonLink} />
            )}
            <CountUpStats />
          </div>

          <div className="lg:mt-0 lg:col-span-5 lg:flex">
            <Reveal>
              <ShiftingContactForm />
            </Reveal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
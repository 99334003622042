import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import emailjs from 'emailjs-com';

const BASE_TRANSITION = { ease: "anticipate", duration: 0.75 };

const ShiftingContactForm = () => {
  const [selected, setSelected] = useState("individual");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    companyName: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false); // New state for loading spinner

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission with validation
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = {};

    // Validation
    if (!formData.name.trim()) validationErrors.name = "Name is required.";
    if (!formData.email.trim()) validationErrors.email = "Email is required.";
    else if (!/\S+@\S+\.\S+/.test(formData.email)) validationErrors.email = "Email address is invalid.";
    if (selected === "company" && !formData.companyName.trim()) validationErrors.companyName = "Company name is required.";
    if (!formData.message.trim()) validationErrors.message = "Please enter a message.";

    setErrors(validationErrors);

    // If no errors, submit the form
    if (Object.keys(validationErrors).length === 0) {
      setLoading(true); // Start loading spinner
      try {
        await emailjs.send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID,
          process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
          {
            name: formData.name,
            email: formData.email,
            companyName: formData.companyName,
            message: formData.message,
          },
          process.env.REACT_APP_EMAILJS_USER_ID
        );

        setStatus({ message: "Email sent successfully!", type: "success" });
        setFormData({ name: "", email: "", companyName: "", message: "" });
        setSelected("individual");
      } catch (error) {
        console.error("Failed to send email:", error);
        setStatus({ message: "An error occurred. Please try again later.", type: "error" });
      } finally {
        setLoading(false); 
      }
    }
  };

  return (
    <div className="w-full max-w-2xl mx-auto shadow-lg rounded-lg overflow-hidden pointer-events-auto">
      <Form
        selected={selected}
        setSelected={setSelected}
        formData={formData}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        errors={errors}
        loading={loading} // Pass loading state to form
      />
      {status && (
        <div
          className={`mt-4 p-4 rounded-md text-center transition-all duration-300 ${
            status.type === "success"
              ? "bg-green-100 text-green-700 border border-green-300"
              : "bg-red-100 text-red-700 border border-red-300"
          }`}
        >
          {status.message}
        </div>
      )}
    </div>
  );
};

const Form = ({
  selected,
  setSelected,
  formData,
  handleChange,
  handleSubmit,
  errors,
  loading,
}) => {
  return (
    <form
      onSubmit={handleSubmit}
      className={`p-8 w-full text-white transition-colors duration-750 ${
        selected === "company" ? "bg-indigo-600" : "bg-violet-600"
      }`}
    >
      <h3 className="text-xl font-bold mb-5">Get in touch, today!</h3>

      {/* Name input */}
      <div className="mb-3">
        <p className="text-l mb-2">Hi 👋! My name is...</p>
        <input
          type="text"
          name="name"
          value={formData.name}
          placeholder="Your name..."
          onChange={handleChange}
          className={`${
            selected === "company" ? "bg-indigo-700" : "bg-violet-700"
          } transition-colors duration-750 placeholder-white/70 p-2 rounded-md w-full focus:outline-none`}
        />
        {errors.name && <p className="text-red-300 mt-1 text-sm">{errors.name}</p>}
      </div>

      {/* Email input */}
      <div className="mb-3">
        <p className="text-l mb-2">My email is...</p>
        <input
          type="email"
          name="email"
          value={formData.email}
          placeholder="Your email..."
          onChange={handleChange}
          className={`${
            selected === "company" ? "bg-indigo-700" : "bg-violet-700"
          } transition-colors duration-750 placeholder-white/70 p-2 rounded-md w-full focus:outline-none`}
        />
        {errors.email && <p className="text-red-300 mt-1 text-sm">{errors.email}</p>}
      </div>

      {/* Company/individual toggle */}
      <div className="mb-3">
        <p className="text-l mb-2">and I represent...</p>
        <FormSelect selected={selected} setSelected={setSelected} />
      </div>

      {/* Company name */}
      <AnimatePresence initial={false}>
        {selected === "company" && (
          <motion.div
            key="company-name"
            initial={{ marginTop: -104, opacity: 0 }}
            animate={{ marginTop: 0, opacity: 1 }}
            exit={{ marginTop: -104, opacity: 0 }}
            transition={BASE_TRANSITION}
            className="mb-3"
          >
            <p className="text-l mb-2">by the name of...</p>
            <input
              type="text"
              name="companyName"
              value={formData.companyName}
              placeholder="Your company name..."
              onChange={handleChange}
              className={`${
                selected === "company" ? "bg-indigo-700" : "bg-violet-700"
              } transition-colors duration-750 placeholder-white/70 p-2 rounded-md w-full focus:outline-none`}
            />
            {errors.companyName && <p className="text-red-300 mt-1 text-sm">{errors.companyName}</p>}
          </motion.div>
        )}
      </AnimatePresence>

      {/* Message */}
      <div className="mb-6">
        <p className="text-l mb-2">I'd love to ask about...</p>
        <textarea
          name="message"
          value={formData.message}
          placeholder="Whatever your heart desires :)"
          onChange={handleChange}
          className={`${
            selected === "company" ? "bg-indigo-700" : "bg-violet-700"
          } transition-colors duration-750 min-h-[120px] resize-none placeholder-white/70 p-2 rounded-md w-full focus:outline-none`}
        />
        {errors.message && <p className="text-red-300 mt-1 text-sm">{errors.message}</p>}
      </div>

      {/* Submit with Loader */}
      <motion.button
        whileHover={{ scale: 1.01 }}
        whileTap={{ scale: 0.99 }}
        type="submit"
        disabled={loading}
        className={`${
          selected === "company"
            ? "bg-white text-indigo-600"
            : "bg-white text-violet-600"
        } transition-colors duration-750 text-lg rounded-lg w-full py-3 font-semibold flex justify-center items-center`}
      >
        {loading ? (
          <svg className="animate-spin h-5 w-5 mr-3 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v4a4 4 0 000 8v4a8 8 0 01-8-8z"></path>
          </svg>
        ) : (
          "Submit"
        )}
      </motion.button>
    </form>
  );
};

const FormSelect = ({ selected, setSelected }) => {
  return (
    <div className="border border-white rounded overflow-hidden font-medium w-fit">
      <button
        type="button"
        className={`${selected === "individual" ? "text-violet-600" : "text-white"} text-sm px-3 py-1.5 transition-colors duration-750 relative`}
        onClick={() => setSelected("individual")}
      >
        <span className="relative z-10">Myself</span>
        {selected === "individual" && (
          <motion.div transition={BASE_TRANSITION} layoutId="form-tab" className="absolute inset-0 bg-white z-0" />
        )}
      </button>
      <button
        type="button"
        className={`${selected === "company" ? "text-indigo-600" : "text-white"} text-sm px-3 py-1.5 transition-colors duration-750 relative`}
        onClick={() => setSelected("company")}
      >
        <span className="relative z-10">A company</span>
        {selected === "company" && (
          <motion.div transition={BASE_TRANSITION} layoutId="form-tab" className="absolute inset-0 bg-white z-0" />
        )}
      </button>
    </div>
  );
};

export default ShiftingContactForm;
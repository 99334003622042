import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import Example from './FloatingPhone';

const ProfileSection = () => {
  const dotRef = useRef(null);

  useEffect(() => {
    // Create a pulsing effect on the dot
    gsap.to(dotRef.current, {
      scale: 1.2,
      repeat: -1,
      yoyo: true,
      duration: 0.6,
      ease: "power1.inOut",
    });
  }, []);

  return (
    <section>
      <div className="mx-auto w-full max-w-7xl px-5 py-12 md:px-10 md:py-16 lg:py-20">
        <div className="grid gap-12 sm:gap-20 lg:grid-cols-2">
          <div className="flex flex-col items-start gap-2">
            {/* Only the dot pulses */}
            <div className="flex items-center rounded-md bg-green-200 px-3 py-1">
              <div ref={dotRef} className="mr-1 h-2 w-2 rounded-full bg-green-600 mr-2"></div>
              <p className="text-sm">Available for work</p>
            </div>
            
            <p className="text-sm text-gray-500 sm:text-xl">
              Founder, PHP & WordPress Expert
            </p>
            <h2 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-5xl">
              Rasmus Plambech
            </h2>
            <p className="max-w-2xl mb-6 font-light lg:mb-8 md:text-lg lg:text-l">
              I’m Rasmus Plambech, the founder of Plambech Development, a dedicated one-man freelance agency based in Copenhagen, Denmark 🇩🇰
              <br></br> <br></br>
              With 10+ years of hands-on experience in PHP, WordPress, and front-end development, I specialize in building custom, high-performing websites and tailored digital solutions. My approach combines technical precision with a strategic eye for SEO, data analytics, and cybersecurity, creating platforms that not only look great but are robust, secure, and optimized for growth. 🚀
              <br/><br/>
              Whether you’re looking to build a standout website or need custom functionality to elevate your online presence, I’m here to help turn your ideas into impactful digital experiences. 
            </p>
          </div>
          <div className="min-h-[530px] overflow-hidden rounded-md">
            <Example />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProfileSection;
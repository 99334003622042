// Home.jsx
import React from 'react';
import CollapseCardFeatures from '../Components/CollapseCardFeatures';
import { CodeBeams } from '../Components/CodeBeams';
import HeroSection from '../Components/HeroSection';
import ProfileSection from '../Components/AboutComponant';
import { FoldingLogos } from '../Components/FoldingLogos';

import { Helmet } from "react-helmet";

const Home = () => {
  // Define the title and description variables
  const title = 'Building Tomorrow’s Digital Solutions Today';
  const description = (
    <>
      At <strong className="font-bold text-white">Plambech Development</strong>, I transform complex ideas into elegant digital experiences as a freelance developer. My personalized approach ensures your digital presence is built for growth and success.
    </>
  );

  return (
    <div>
    <Helmet>
    <title>Plambech Development | Freelance PHP & WordPress Expert</title>
    <meta name="description" content="Custom WordPress and PHP development by Rasmus Plambech, a freelance developer with over 10 years of experience transforming ideas into digital solutions." />

      {/* Canonical Tag */}
      <link rel="canonical" href="https://plambech.dev/" />

      {/* Open Graph Tags */}
      <meta property="og:title" content="Plambech Development | Freelance PHP & WordPress Expert" />
      <meta property="og:description" content="Custom WordPress and PHP development by Rasmus Plambech, a freelance developer with over 10 years of experience transforming ideas into digital solutions." />
      <meta property="og:url" content="https://plambech.dev/" />

      {/* Twitter Card Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Plambech Development | Freelance PHP & WordPress Expert" />
      <meta name="twitter:description" content="Custom WordPress and PHP development by Rasmus Plambech, a freelance developer with over 10 years of experience transforming ideas into digital solutions." />

      {/* Additional Meta Tags */}
      <meta name="robots" content="index, follow" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta charset="UTF-8" />
      <meta name="author" content="Rasmus Plambech" />
    </Helmet>

      {/* Pass the variables as props to HeroSection */}
      <HeroSection
        title={title}
        description={description}
        buttonText="See my work!"
        buttonLink="/portfolio"
      />

      <CollapseCardFeatures />
      <CodeBeams />
      <ProfileSection />
      <FoldingLogos />
    </div>
  );
};

export default Home;
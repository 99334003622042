// HeroSection.jsx
import React from 'react';
import HoverGrid from './HoverGrid';
import NeuFollowButton from './NeuFollowButton';
import plambechImage from '../assets/img/plambech-development-rasmus.jpg';

function HeroSection({ title, description }) {
  return (
    <div className="relative min-h-screen bg-neutral-950 flex justify-center items-center flex-wrap">
      {/* Canvas Background */}
      <HoverGrid />

      {/* Content Overlay */}
      <div className="relative pointer-events-none h-full inset-0 flex flex-col items-start justify-center pt-32 md:pt-24 pb-8 px-8 mx-auto w-full max-w-7xl">
        <div className="grid max-w-screen-xl py-8 mx-auto lg:gap-8 xl:gap-10 lg:py-16 lg:grid-cols-12">
          <div className="mr-auto place-self-center lg:col-span-7">
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold leading-none md:text-5xl xl:text-5xl text-white">
              Rasmus Plambech, Freelance Developer
            </h1>
            <p className="max-w-2xl mb-6 font-light text-gray-400 lg:mb-8 md:text-lg lg:text-xl">
              I am a seasoned freelance web developer with over 10 years of experience in crafting dynamic and efficient online platforms. Specializing in WordPress, PHP, JavaScript, and SEO techniques, 
              <br></br><br></br>
              I focus on delivering high-performance, user-friendly websites tailored to your specific needs. My passion is transforming complex ideas into seamless digital experiences that not only look great but also drive tangible results. Let’s collaborate to bring your vision to life and make a lasting impact online.
            </p>
            <NeuFollowButton text="Contact me!" link="/contact" />
          </div>

          <div className="col-span-5">
            <div
              className="morph-background min-h-[24rem] w-full mt-8 md:mt-0"
              style={{
                backgroundImage: `url(${plambechImage})`,
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
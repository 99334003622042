import React, { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { SiWordpress, SiGithub, SiGoogleanalytics, SiCloudflare, SiSalesforce, SiSemrush } from "react-icons/si";
import { twMerge } from "tailwind-merge";
import NeuFollowButton from './NeuFollowButton';

export const FoldingLogos = () => {
  return (
    <section className="flex flex-col items-center justify-center gap-12 bg-darkBlue md:flex-row w-full">
      <div className="mx-auto w-full max-w-7xl px-5 py-12 md:px-10 md:py-16 lg:py-20 flex flex-col md:flex-row gap-12">
        <Copy />
        <LogoRolodex
          items={[
          <LogoItem key={1} className="bg-blue-600 text-white">
            <SiWordpress />
          </LogoItem>,
          <LogoItem key={2} className="bg-orange-500 text-white">
            <SiGoogleanalytics />
          </LogoItem>,
          <LogoItem key={3} className="bg-gray-900 text-white">
            <SiGithub />
          </LogoItem>,
          <LogoItem key={4} className="bg-orange-400 text-white">
            <SiCloudflare />
          </LogoItem>,
          <LogoItem key={5} className="bg-blue-500 text-white">
            <SiSalesforce />
          </LogoItem>,
          <LogoItem key={6} className="bg-orange-600 text-white">
            <SiSemrush />
          </LogoItem>,
          ]}
        />
      </div>
    </section>
  );
};

const Copy = () => (
  <div className="text-left md:text-start">
    <h2 className="mb-3 text-4xl text-white">Power Up Your Online Presence</h2>
    <p className="mb-8 md:text-lg lg:text-l text-neutral-400">
      Need a new website, assistance with Salesforce integration, SEO optimization, or help implementing server-side tagging? I'm here to make it happen. From seamless digital transformations to data-driven, search-friendly solutions, I'm your partner in creating powerful, customized web experiences. Reach out today to start the conversation!
    </p>
    <NeuFollowButton text="Contact now" link="/contact" />
  </div>
);

const DELAY_IN_MS = 2500;
const TRANSITION_DURATION_IN_SECS = 1.5;

const LogoRolodex = ({ items }) => {
  const intervalRef = useRef(null);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setIndex((pv) => pv + 1);
    }, DELAY_IN_MS);

    return () => {
      clearInterval(intervalRef.current || undefined);
    };
  }, []);

  return (
    <div
      style={{
        transform: "rotateY(-20deg)",
        transformStyle: "preserve-3d",
      }}
      className="relative z-0 h-44 w-60 shrink-0 rounded-xl border border-neutral-700 bg-neutral-800"
    >
      <AnimatePresence mode="sync">
        <motion.div
          style={{
            y: "-50%",
            x: "-50%",
            clipPath: "polygon(0 0, 100% 0, 100% 50%, 0 50%)",
            zIndex: -index,
            backfaceVisibility: "hidden",
          }}
          key={index}
          transition={{
            duration: TRANSITION_DURATION_IN_SECS,
            ease: "easeInOut",
          }}
          initial={{ rotateX: "0deg" }}
          animate={{ rotateX: "0deg" }}
          exit={{ rotateX: "-180deg" }}
          className="absolute left-1/2 top-1/2"
        >
          {items[index % items.length]}
        </motion.div>
        <motion.div
          style={{
            y: "-50%",
            x: "-50%",
            clipPath: "polygon(0 50%, 100% 50%, 100% 100%, 0 100%)",
            zIndex: index,
            backfaceVisibility: "hidden",
          }}
          key={(index + 1) * 2}
          initial={{ rotateX: "180deg" }}
          animate={{ rotateX: "0deg" }}
          exit={{ rotateX: "0deg" }}
          transition={{
            duration: TRANSITION_DURATION_IN_SECS,
            ease: "easeInOut",
          }}
          className="absolute left-1/2 top-1/2"
        >
          {items[index % items.length]}
        </motion.div>
      </AnimatePresence>

      <hr
        style={{
          transform: "translateZ(1px)",
        }}
        className="absolute left-0 right-0 top-1/2 z-[999999999] -translate-y-1/2 border-t-2 border-neutral-800"
      />
    </div>
  );
};

const LogoItem = ({ children, className }) => {
  return (
    <div
      className={twMerge(
        "grid h-36 w-52 place-content-center rounded-lg bg-neutral-700 text-6xl text-neutral-50",
        className
      )}
    >
      {children}
    </div>
  );
};
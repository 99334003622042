import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLaptopCode,
  faRedo,
  faBug,
  faPuzzlePiece,
  faTachometerAlt,
  faShieldAlt,
  faSearch,
  faChartLine,
  faServer,
  faTasks,
  faCookieBite,
  faLanguage,
} from '@fortawesome/free-solid-svg-icons';

const serviceList = [
  {
    icon: faLaptopCode,
    title: 'Developing Brand-New Websites',
    description:
      'Creating custom, responsive websites from scratch to meet your unique business needs.',
  },
  {
    icon: faRedo,
    title: 'Revitalizing Existing Sites',
    description:
      'Refreshing and modernizing your current website to enhance user experience and engagement.',
  },
  {
    icon: faBug,
    title: 'Bug Fixes and Updates',
    description:
      'Identifying and resolving issues to ensure your website runs smoothly and efficiently.',
  },
  {
    icon: faPuzzlePiece,
    title: 'Custom Plugin Development',
    description:
      'Developing custom WordPress plugins to extend functionality and improve performance.',
  },
  {
    icon: faTachometerAlt,
    title: 'Performance Optimization',
    description:
      'Enhancing website speed and performance for better user experience and SEO ranking.',
  },
  {
    icon: faShieldAlt,
    title: 'GDPR Compliance',
    description:
      'Implementing data protection measures to ensure compliance with GDPR regulations.',
  },
  {
    icon: faSearch,
    title: 'SEO Audits & Optimization',
    description:
      'Analyzing and improving your website’s SEO to increase visibility and organic traffic.',
  },
  {
    icon: faChartLine,
    title: 'Advanced Tracking',
    description:
      'Setting up advanced analytics to track user behavior and website performance.',
  },
  {
    icon: faServer,
    title: 'Server-Side Tagging',
    description:
      'Transforming third-party cookies into first-party cookies through server-side tagging for enhanced data control and privacy.',
  },
  {
    icon: faTasks,
    title: 'Project Management',
    description:
      'Providing project management services to ensure timely and successful project delivery.',
  },
  {
    icon: faCookieBite,
    title: 'Cookie Compliance',
    description:
      'Ensuring your website complies with cookie laws and regulations.',
  },
  {
    icon: faLanguage,
    title: 'Translation To/From Danish',
    description:
      'Offering professional translation services between Danish and English.',
  },
];

const ServiceItem = ({ service }) => (
  <div className="bg-white shadow-xl rounded-2xl p-6 pb-12 h-full">
    <div className="w-20 h-20 rounded-full text-3xl flex items-center justify-center -mt-16 bg-white shadow-2xl text-violet-600">
      <FontAwesomeIcon icon={service.icon} />
    </div>

    <div className="mt-6">
      <h4 className="text-2xl font-medium mb-4">{service.title}</h4>
      <p className="opacity-80">{service.description}</p>
    </div>
  </div>
);

ServiceItem.propTypes = {
  service: PropTypes.shape({
    icon: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};

const ServicesSection = () => {
  return (
    <section className="services-section mx-auto w-full max-w-7xl px-5 py-12 md:px-10 md:py-16 lg:py-20 bg-white text-zinc-900 relative z-[1] overflow-hidden">
      <div className="container mx-auto">
        <div className="absolute top-16 right-0 bottom-16 left-1/2 lg:left-[60%] rounded-l-[30px] bg-violet-600 bg-opacity-10 w-[100vw] -z-[1]" />
        <div className="w-full max-w-xl mb-12">
          <h2 className="text-4xl leading-none md:text-[45px] font-bold mb-4">
            Services I Offer
          </h2>
          <p className="text-lg opacity-80 mb-6">
            Explore my range of services designed to bring your ideas to life. I combine
            innovation and expertise to deliver solutions that drive success.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 gap-y-20 mt-20 max-w-7xl mx-auto">
          {serviceList.map((service, i) => (
            <div key={i}>
              <ServiceItem service={service} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
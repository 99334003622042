import React, { useState } from "react";
import SpringModal from "./SpringModal";
import PortfolioCard from "./PortfolioCard";
import { AiOutlineExport } from "react-icons/ai";

// Import images directly
import Logpoint from "../assets/img/logpoint-website-scaled.webp";
import BII from "../assets/img/bii-website-scaled.webp";
import Raunsborg from "../assets/img/raunsborg-website-scaled.webp";
import Novo from "../assets/img/novo-nordisk-fonden-website-scaled.webp";
import SB from "../assets/img/securitybridge-website.png";
import cityContainer from "../assets/img/citycontainer-webshop.png";


export const BouncyCardsFeatures = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    description: [],
    imgSrc: "",
    tech: [],
    projectLinks: [],
  });

  const openModal = (content) => {
    setModalContent(content);
    setIsOpen(true);
  };

  return (
    <section className="mx-auto max-w-7xl px-4 py-32 text-slate-800">
      <div className="mb-12 md:flex-row md:items-end md:px-4">
      <h2 class="text-4xl font-bold md:text-5xl">
        Building Brands & Experiences<span class="text-slate-400"><br></br>Dive into My Projects</span>
        </h2>
      </div>
      <div className="mb-4 grid grid-cols-12 gap-8">
        <PortfolioCard
          title="SecurityBridge"
          description={[
            "Leading a complete rebrand for SecurityBridge, including a full website overhaul to reflect the updated brand identity and business goals.",
            "Integrating CRM systems to streamline lead management and client interactions, while setting up advanced tracking to monitor user behavior, conversions, and site performance.",
            "Implementing server optimizations to enhance speed and scalability, along with technical SEO improvements to boost search engine visibility.",
            "Providing ongoing consulting to support continuous improvement, ensuring the site remains optimized, secure, and aligned with SecurityBridge’s strategic objectives."
          ]}
          imgSrc={SB}
          tech={["WordPress", "PHP", "JavaScript", "Salesforce", "Cloudflare", "GA4"]}
          projectLinks={[
            {
              url: "https://securitybridge.com/",
              icon: <AiOutlineExport />,
              label: "Live Project",
            },
          ]}
          bgColor="from-violet-400 to-indigo-400"
          openModal={openModal}
        />
        
        <PortfolioCard
          title="Logpoint"
          description={[
            "Leading a complete rebranding and website overhaul, handling everything from development to CRM integration, server maintenance, and performance optimization.",
            "Managing seamless CRM integration for automated lead tracking and customer relationship management, alongside robust server maintenance and scaling strategies.",
            "Setting up advanced tracking and analytics for user insights, conversion tracking, and A/B testing to drive continuous improvements.",
            "Providing regular reporting on traffic, engagement, and conversions, along with ongoing support for performance, security, and client updates."
          ]}
          imgSrc={Logpoint} // Use imported image
          tech={["WordPress", "PHP", "JavaScript", "Salesforce", "Cloudflare", "GA4"]}
          projectLinks={[
            {
              url: "https://logpoint.com/",
              icon: <AiOutlineExport />,
              label: "Live Project",
            },
          ]}
          bgColor="from-violet-400 to-indigo-400"
          openModal={openModal}
        />

        <PortfolioCard
          title="Novo Nordisk Fonden"
          description={[
            "As part of my role at Bærnholdt, I handled development and maintenance for Novo Nordisk Fonden’s website, focusing on new feature creation and ongoing support.",
            "Projects included developing complex interactive elements, such as a dynamic timeline, to enhance user engagement and effectively display important milestones.",
            "I was responsible for general maintenance and performance optimization, ensuring a seamless and reliable user experience."
          ]}
          imgSrc={Novo} // Use imported image
          tech={["Node", "Express", "Postgres", "Kafka", "Redis"]}
          projectLinks={[
            {
              url: "https://novonordiskfonden.dk/",
              icon: <AiOutlineExport />,
              label: "Live Project",
            },
          ]}
          bgColor="from-violet-400 to-indigo-400"
          openModal={openModal}
        />

        <PortfolioCard
          title="BioInnovation Institute"
          description={[
            "In my role at Bærnholdt, I developed new features for BII’s website, including the addition of new page elements using ACF (Advanced Custom Fields) to enhance flexibility and customization.",
            "Conducted a comprehensive SEO audit and implemented on-page technical SEO optimizations to improve search engine visibility and site performance.",
            "Oversaw technical adjustments to ensure the site adhered to best practices, boosting overall SEO health and usability."
          ]}
          imgSrc={BII} // Use imported image
          tech={["WordPress", "PHP", "ACF", "SEO", "Audit"]}
          projectLinks={[
            {
                url: "https://bii.dk/",
                icon: <AiOutlineExport />,
                label: "Live Project",
            },
          ]}
          bgColor="from-violet-400 to-indigo-400"
          openModal={openModal}
        />

        <PortfolioCard
          title="Raunsborg"
          description={[
            "At StandoutMedia, I developed a WooCommerce shop for Raunsborg, implementing advanced discount models and product bundling to enhance the customer shopping experience and drive sales.",
            "Integrated detailed tracking solutions to monitor user behavior, conversions, and product performance, providing valuable insights for ongoing optimization.",
            "Ensured seamless functionality and customization across the shop, aligning with Raunsborg’s brand and e-commerce goals."
          ]}
          imgSrc={Raunsborg}
          tech={["WordPress", "WooCommerce", "PHP", "JavaScript", "Marketing"]}
          projectLinks={[
            {
              url: "https://bii.dk/",
              icon: <AiOutlineExport />,
              label: "Live Project",
            },
          ]}
          bgColor="from-violet-400 to-indigo-400"
          openModal={openModal}
        />

        <PortfolioCard
          title="City Container"
          description={[
            "As part of my role at Bærnholdt, I expanded City Containers existing WordPress site by adding a WooCommerce webshop, providing a smooth shopping experience for users.",
            "Focused on integrating the webshop seamlessly with the existing site structure, built with PHP and WordPress, ensuring consistent functionality and branding.",
            "Handled all aspects of the WooCommerce setup, including product listings, payment integration, and user experience enhancements."
          ]}
          imgSrc={cityContainer} // Use imported image
          tech={["Node", "Express", "Postgres", "Kafka", "Redis"]}
          projectLinks={[
            {
              url: "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
              icon: <AiOutlineExport />,
              label: "Live Project",
            },
          ]}
          bgColor="from-violet-400 to-indigo-400"
          openModal={openModal}
        />
      </div>
      <SpringModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={modalContent.title}
        description={modalContent.description}
        imgSrc={modalContent.imgSrc}
        tech={modalContent.tech}
        projectLinks={modalContent.projectLinks}
      />
    </section>
  );
};

export default BouncyCardsFeatures;
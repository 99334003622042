// Home.jsx
import React from 'react';
import HeroSection from '../Components/HeroSection';
import { FoldingLogos } from '../Components/FoldingLogos';
import { Helmet } from "react-helmet";
import ServicesSection from '../Components/ServicesSection';

const Services = () => {
  // Define the title and description variables
  const title = 'Delivering Bespoke Web Development Services';

  const description = (
    <>
      Explore a range of services designed to transform your digital presence. Specializing in WordPress, PHP, JavaScript, and SEO, I craft custom solutions that drive growth and set your brand apart online.
    </>
  );

  return (
    <div>
    <Helmet>
    <title>Services | Plambech Development - WordPress & PHP Expert</title>
    <meta name="description" content="Discover services offered by freelance developer Rasmus Plambech: custom WordPress development, PHP programming, and tailored digital solutions." />

      {/* Canonical Tag */}
      <link rel="canonical" href="https://plambech.dev/services" />

      {/* Open Graph Tags */}
      <meta property="og:title" content="Services | Plambech Development - WordPress & PHP Expert" />
      <meta property="og:description" content="Discover services offered by freelance developer Rasmus Plambech: custom WordPress development, PHP programming, and tailored digital solutions." />
      <meta property="og:url" content="https://plambech.dev/" />

      {/* Twitter Card Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Services | Plambech Development - WordPress & PHP Expert" />
      <meta name="twitter:description" content="Discover services offered by freelance developer Rasmus Plambech: custom WordPress development, PHP programming, and tailored digital solutions." />

      {/* Additional Meta Tags */}
      <meta name="robots" content="index, follow" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta charset="UTF-8" />
      <meta name="author" content="Rasmus Plambech" />
    </Helmet>

      {/* Pass the variables as props to HeroSection */}
      <HeroSection
        title={title}
        description={description}
        buttonText="See my cases!"
        buttonLink="/portfolio"
      />
      <ServicesSection />
      <FoldingLogos />
    </div>
  );
};

export default Services;
// Home.jsx
import React from 'react';
import HeroSection from '../Components/HeroSection';

import { Helmet } from "react-helmet";

const Contact = () => {
  // Define the title and description variables
  const title = 'Get in Touch with Plambech Development Today!';
  const description = (
    <>
      Looking for a skilled freelance developer to bring your digital ideas to life? At <strong className="font-bold text-white">Plambech Development</strong>, I specialize in creating custom, high-performing websites and tailored digital solutions. Let's collaborate to elevate your online presence and drive growth.
    </>
  );

  return (
    <div>
    <Helmet>
    <title>Contact Plambech Development | Freelance Developer</title>
    <meta
        name="description"
        content="Get in touch with Rasmus Plambech, a freelance web developer with over 10 years of experience. Specializing in WordPress, PHP, and custom digital solutions to elevate your online presence."
      />

      {/* Canonical Tag */}
      <link rel="canonical" href="https://plambech.dev/contact" />

      {/* Open Graph Tags */}
      <meta property="og:title" content="Contact Plambech Development | Freelance Developer" />
      <meta property="og:description" content="Connect with Rasmus Plambech to discuss your web development needs. I specialize in custom, high-performing websites and digital solutions for growth." />
      <meta property="og:url" content="https://plambech.dev/contact" />

      {/* Twitter Card Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Contact Plambech Development | Freelance Developer" />
      <meta name="twitter:description" content="Reach out to Rasmus Plambech for custom WordPress, PHP, and SEO solutions tailored to your business." />

      {/* Additional Meta Tags */}
      <meta name="robots" content="index, follow" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta charset="UTF-8" />
      <meta name="author" content="Rasmus Plambech" />
    </Helmet>

      {/* Pass the variables as props to HeroSection */}
      <HeroSection
        title={title}
        description={description}
        buttonText="See my work!"
        buttonLink="/portfolio"
      />
    </div>
  );
};

export default Contact;
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Nav } from './Components/CornerNav'; 
import Footer from './Components/Footer'; 
import AppRoutes from './router';
import OrganizationSchema from './Components/Helmet';

function App() {
  return (
    <>
      <OrganizationSchema /> {/* Global SEO metadata */}
      <Router>
        <div className="App">
          <Nav /> {/* Persistent navigation */}
          <AppRoutes /> {/* Routing logic */}
          <Footer /> {/* Persistent footer */}
        </div>
      </Router>
    </>
  );
}

export default App;
// helmet.js

import React from 'react';
import { Helmet } from 'react-helmet';
import socialShare from '../assets/img/rasmus-plambech-social-share.jpg';

const GlobalHelmet = () => {
  const organizationSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "@id": "https://plambech.dev/#organization",
    "name": "Plambech Development",
    "legalName": "Plambech Development",
    "url": "https://plambech.dev/",
    "description": "Plambech Development is a freelance development agency founded by Rasmus Plambech, specializing in WordPress development, marketing, SEO optimization, tracking, and data analysis. With over 10 years of hands-on experience in PHP, WordPress, and front-end development, I excel in transforming complex ideas into elegant digital experiences. Our personalized approach ensures your digital presence is built for growth, security, and success. We offer custom, high-performing websites and tailored digital solutions, combining technical precision with a strategic eye for SEO, data analytics, and cybersecurity. Whether you're looking to build a standout website, need assistance with Salesforce integration, SEO optimization, or help implementing server-side tagging, Plambech Development is here to turn your ideas into impactful digital experiences.",
    "email": "info@plambech.dev",
    "sameAs": [
      "https://www.linkedin.com/in/rasmusplambech/",
    ],
  };

  return (
    <Helmet>
      {/* Organization Schema */}
      <script type="application/ld+json">
        {JSON.stringify(organizationSchema)}
      </script>

      {/* Additional Meta Tags */}
      <meta name="robots" content="index, follow" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta charSet="UTF-8" />
      <meta name="author" content="Rasmus Plambech" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta name="twitter:label1" content="Written by" />
      <meta name="twitter:data1" content="Rasmus Plambech" />

      {/* Social Media Image Tag with Imported Image */}
      <meta property="og:image" content={socialShare} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={socialShare} />

    </Helmet>
  );
};

export default GlobalHelmet;
import { AnimatePresence, motion } from "framer-motion";
import { MdClose } from "react-icons/md";

const SpringModal = ({
  isOpen,
  setIsOpen,
  title,
  description,
  imgSrc,
  tech,
  projectLinks,
}) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={() => setIsOpen(false)}
          className="fixed inset-0 z-50 px-4 py-12 bg-zinc-950/50 backdrop-blur overflow-y-scroll flex justify-center cursor-pointer"
        >
          <button
            onClick={() => setIsOpen(false)}
            className="absolute top-4 md:top-6 text-xl right-4"
          >
            <MdClose />
          </button>
          <motion.div
            initial={{ scale: 0, rotate: "12.5deg" }}
            animate={{ scale: 1, rotate: "0deg" }}
            exit={{ scale: 0, rotate: "0deg" }}
            onClick={(e) => e.stopPropagation()}
            className="w-full max-w-2xl h-fit rounded-lg overflow-hidden bg-darkBlue shadow-lg cursor-auto"
          >
            <img alt="" className="w-full" src={imgSrc} />
            <div className="p-8">
              <h4 className="text-3xl text-white font-bold mb-2">{title}</h4>
              <div className="flex flex-wrap gap-2 text-sm text-indigo-300 mb-4">
                {tech.map((techItem, index) => (
                  <span key={index}>{techItem}</span>
                ))}
              </div>
              <div className="space-y-4 my-6 leading-relaxed text-sm text-zinc-300">
                {description.map((para, index) => (
                  <p key={index}>{para}</p>
                ))}
              </div>
              <div>
                <p className="font-bold text-white mb-2 text-xl">
                  Project Links<span className="text-indigo-500">.</span>
                </p>
                <div className="flex items-center gap-4 text-sm">
                  {projectLinks.map((link, index) => (
                    <a
                      key={index}
                      target="_blank"
                      rel="nofollow noreferrer"
                      className="text-zinc-300 hover:text-indigo-300 transition-colors flex items-center gap-1"
                      href={link.url}
                    >
                      {link.icon} {link.label}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SpringModal;
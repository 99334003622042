import React, { useState, useEffect } from 'react';
import { FiZap } from "react-icons/fi";
import { motion } from "framer-motion";
import { SiGithub } from "react-icons/si";
import { FiStar } from "react-icons/fi";
import Highlight, { Prism } from 'prism-react-renderer';
import theme from 'prism-react-renderer/themes/dracula';

// Import Prism languages
(typeof global !== 'undefined' ? global : window).Prism = Prism;
require('prismjs/components/prism-php');
require('prismjs/components/prism-sql');

export const CodeBeams = () => {
  return (
    <div className="relative overflow-hidden bg-darkBlue text-slate-200">
      <div className="mx-auto max-w-7xl px-4 md:px-8">
        <section className="relative z-20 py-20 md:py-36">
          <span className="mx-auto mb-3 block w-fit rounded bg-gradient-to-br from-slate-800 to-slate-950 p-3 text-3xl shadow-md shadow-indigo-900">
            <FiZap />
          </span>
          <h2 className="mb-3 text-center text-3xl font-semibold leading-tight sm:text-4xl">
            Your Ideas, Expertly Coded
          </h2>
          <p className="mb-6 text-center text-base leading-snug text-slate-400 sm:text-lg sm:leading-snug md:text-xl md:leading-snug">
            From front-end to back-end, I use various languages to make your vision a reality.       
          </p>
          <CodeCard />
        </section>
      </div>
      <BGGrid />
    </div>
  );
};

const BGGrid = () => {
  return (
    <div
      style={{
        backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='32' height='32' fill='none' stroke-width='2' stroke='rgb(30 27 75 / 0.5)'%3e%3cpath d='M0 .5H31.5V32'/%3e%3c/svg%3e")`,
      }}
      className="absolute bottom-0 left-0 right-0 top-0"
    >
      <div className="absolute inset-0 bg-gradient-to-b from-slate-950/80 via-slate-950/0 to-slate-950/80" />
      <Beams />
    </div>
  );
};

const Beams = () => {
  const { width } = useWindowSize();

  const numColumns = width ? Math.floor(width / GRID_BOX_SIZE) : 0;

  const placements = [
    {
      top: GRID_BOX_SIZE * 0,
      left: Math.floor(numColumns * 0.05) * GRID_BOX_SIZE,
      transition: {
        duration: 3.5,
        repeatDelay: 5,
        delay: 2,
      },
    },
    {
      top: GRID_BOX_SIZE * 12,
      left: Math.floor(numColumns * 0.15) * GRID_BOX_SIZE,
      transition: {
        duration: 3.5,
        repeatDelay: 10,
        delay: 4,
      },
    },
    {
      top: GRID_BOX_SIZE * 3,
      left: Math.floor(numColumns * 0.25) * GRID_BOX_SIZE,
    },
    {
      top: GRID_BOX_SIZE * 9,
      left: Math.floor(numColumns * 0.75) * GRID_BOX_SIZE,
      transition: {
        duration: 2,
        repeatDelay: 7.5,
        delay: 3.5,
      },
    },
    {
      top: 0,
      left: Math.floor(numColumns * 0.7) * GRID_BOX_SIZE,
      transition: {
        duration: 3,
        repeatDelay: 2,
        delay: 1,
      },
    },
    {
      top: GRID_BOX_SIZE * 2,
      left: Math.floor(numColumns * 1) * GRID_BOX_SIZE - GRID_BOX_SIZE,
      transition: {
        duration: 5,
        repeatDelay: 5,
        delay: 5,
      },
    },
  ];

  return (
    <>
      {placements.map((p, i) => (
        <Beam
          key={i}
          top={p.top}
          left={p.left - BEAM_WIDTH_OFFSET}
          transition={p.transition || {}}
        />
      ))}
    </>
  );
};

const Beam = ({ top, left, transition = {} }) => {
  return (
    <motion.div
      initial={{
        y: 0,
        opacity: 0,
      }}
      animate={{
        opacity: [0, 1, 0],
        y: 32 * 8,
      }}
      transition={{
        ease: "easeInOut",
        duration: 3,
        repeat: Infinity,
        repeatDelay: 1.5,
        ...transition,
      }}
      style={{
        top,
        left,
      }}
      className="absolute z-10 h-[64px] w-[1px] bg-gradient-to-b from-indigo-500/0 to-indigo-500"
    />
  );
};

const CodeCard = () => {
  const [selected, setSelected] = useState("html");

  return (
    <Card className="mx-auto max-w-3xl pt-3">
      <div className="-mx-6 mb-6 flex items-center justify-between border-b border-slate-700 px-6 pb-3">
        <div className="flex items-center gap-3">
          <ToggleChip
            onClick={() => setSelected("html")}
            selected={selected === "html"}
          >
            HTML
          </ToggleChip>
          <ToggleChip
            onClick={() => setSelected("css")}
            selected={selected === "css"}
          >
            CSS
          </ToggleChip>
          <ToggleChip
            onClick={() => setSelected("js")}
            selected={selected === "js"}
          >
            JavaScript
          </ToggleChip>
          <ToggleChip
            onClick={() => setSelected("php")}
            selected={selected === "php"}
          >
            PHP
          </ToggleChip>
          <ToggleChip
            onClick={() => setSelected("sql")}
            selected={selected === "sql"}
          >
            SQL
          </ToggleChip>
        </div>
        <button
          className={`
                relative z-0 flex items-center gap-2 overflow-hidden whitespace-nowrap rounded-md 
                bg-gradient-to-br from-slate-800 to-slate-950 px-3 py-1.5
                text-xs font-medium text-slate-50
                transition-all duration-300
                
                before:absolute before:inset-0
                before:-z-10 before:translate-y-[200%]
                before:scale-[2.5]
                before:rounded-[100%] before:bg-slate-100
                before:transition-transform before:duration-500
                before:content-[""]
        
                hover:scale-105 hover:text-slate-900
                hover:before:translate-y-[0%]
                active:scale-100`}
        >
          <SiGithub />
          <FiStar className="hidden sm:inline" />
          <span className="hidden sm:inline">1337</span>
        </button>
      </div>
      <div className="-mx-6 overflow-x-auto px-6">
        <Markup
          code={
            selected === "html"
              ? htmlCode
              : selected === "css"
              ? cssCode
              : selected === "js"
              ? javascriptCode
              : selected === "php"
              ? phpCode
              : sqlCode
          }
          language={
            selected === "html"
              ? "markup"
              : selected === "css"
              ? "css"
              : selected === "js"
              ? "javascript"
              : selected === "php"
              ? "php"
              : "sql"
          }
        />
      </div>
      <span className="absolute left-0 top-1/2 h-48 w-[1px] -translate-y-1/2 animate-pulse bg-gradient-to-b from-indigo-500/0 via-indigo-800 to-indigo-500/0" />
    </Card>
  );
};

const ToggleChip = ({ children, selected, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={`rounded px-1.5 py-0.5 text-sm font-medium transition-colors ${
        selected ? "bg-indigo-600 text-slate-50" : "bg-slate-900 text-slate-50 hover:bg-slate-700"
      }`}
    >
      {children}
    </button>
  );
};

const Card = ({ className, children }) => {
  return (
    <motion.div
      initial={{
        filter: "blur(4px)",
      }}
      whileInView={{
        filter: "blur(0px)",
      }}
      transition={{
        duration: 0.5,
        ease: "easeInOut",
        delay: 0.25,
      }}
      className={`relative h-full w-full overflow-hidden rounded-2xl border border-slate-800 bg-gradient-to-br from-slate-950/50 to-slate-900/80 p-6 ${className}`}
    >
      {children}
    </motion.div>
  );
};

const Markup = ({ code, language }) => {
  return (
    <Highlight Prism={Prism} theme={theme} code={code} language={language}>
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
               <pre
               className={className}
               style={{
                 ...style,
                 padding: "0.5em",
                 overflowX: "scroll", 
                 msOverflowStyle: "none", 
                 scrollbarWidth: "none", 
                 whiteSpace: "pre",  
               }}
             >
          {tokens.map((line, i) => {
            // Destructure key from getLineProps
            const { key, ...restLineProps } = getLineProps({ line, key: i });
            return (
              <div key={key} {...restLineProps}>
                <span className="inline-block w-[40px] select-none text-slate-400">
                  {i + 1}
                </span>
                {line.map((token, key) => {
                  // Destructure key from getTokenProps
                  const { key: tokenKey, ...restTokenProps } = getTokenProps({ token, key });
                  return <span key={tokenKey} {...restTokenProps} />;
                })}
              </div>
            );
          })}
        </pre>
      )}
    </Highlight>
  );
};

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const handleResize = () =>
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowSize;
};

const GRID_BOX_SIZE = 32;
const BEAM_WIDTH_OFFSET = 1;

// HTML Code Example
const htmlCode = `<!-- Plain old HTML -->
<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <title>Code Portfolio Showcase</title>
  <link rel="stylesheet" href="styles.css">
</head>
<body>
  <div class="card">
    <h1>Hello, I'm Rasmus! 👋</h1>
    <p>I transform coffee into code and dreams into reality. ☕💻</p>
    <button id="magicButton">Click me!</button>
  </div>

  <script>
    // Adding interactivity with vanilla JavaScript
    document.getElementById('magicButton').addEventListener('click', function() {
      alert('Abracadabra! 🎩✨ Your journey into code begins.');
    });
  </script>
</body>
</html>`;

// CSS Code Example
const cssCode = `/* Vanilla.. But I also use Sass & Tailwind depending on project*/

body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #f0f0f0;
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.card {
  background: linear-gradient(135deg, #72edf2 10%, #5151e5 100%);
  padding: 40px 60px;
  border-radius: 15px;
  text-align: center;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  animation: fadeInUp 1s ease-out;
}

.card h1 {
  font-size: 2.5em;
  color: #fff;
  margin-bottom: 10px;
}

.card p {
  font-size: 1.2em;
  color: #e0e0e0;
  margin-bottom: 30px;
}

#magicButton {
  background: #fff;
  color: #5151e5;
  border: none;
  padding: 15px 30px;
  border-radius: 30px;
  font-size: 1em;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

#magicButton:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(81, 81, 229, 0.3);
}

/* Keyframe animation for the card entrance */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 40px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}`;

// JavaScript Code Example
const javascriptCode = `// Simple JavaScript example
document.addEventListener('DOMContentLoaded', () => {
  console.log('Hello from JavaScript! 🚀');
  
  // Checking for jQuery, because sometimes old friends stick around
  if (typeof $ !== 'undefined') {
    console.log("Hello from jQuery! 🕰️ Still kicking it like it's 2010!");
    $('#myElement').text("jQuery is here, doing its thing!");
  }
  
  // And if React has entered the chat...
  if (typeof React !== 'undefined') {
    console.log("React says 👋");
    const MyComponent = () => <div>Hello from React! ⚛️</div>;
    console.log(MyComponent().props.children);
  }
});`;

// PHP Code Example
const phpCode = `<?php
// Enchanted PHP example
echo "Greetings from the mystical realms of PHP! 🐘✨";

// Checking if we're within the WordPress kingdom
if (function_exists('add_action')) {
    echo "Ah, WordPress is our realm! Let's weave some spells. 🧙‍♀️";
    add_action('init', function() {
        echo "The WordPress 'init' incantation has been cast! 🔮";
    });
} else {
    echo "We're in the wild lands of PHP without WordPress magic. Brace yourself! 🏞️";
}

// Casting a WordPress loop spell
if (function_exists('have_posts')) {
    while (have_posts()) {
        the_post();
        echo "<h2>" . get_the_title() . " 📜</h2>";
        echo "<div>" . get_the_content() . "</div>";
    }
} else {
    echo "No WordPress scrolls to read from. 📚";
}
?>`;

// SQL Code Example
const sqlCode = `-- Simple SQL example
-- Let's mimic WordPress's database structure 🛠️

CREATE DATABASE wordpress_db;

USE wordpress_db;

-- Creating a simplified version of the wp_posts table
CREATE TABLE wp_posts (
  ID BIGINT UNSIGNED AUTO_INCREMENT PRIMARY KEY,
  post_author BIGINT UNSIGNED NOT NULL DEFAULT '0',
  post_date DATETIME NOT NULL DEFAULT CURRENT_TIMESTAMP,
  post_content LONGTEXT NOT NULL,
  post_title TEXT NOT NULL,
  post_status VARCHAR(20) NOT NULL DEFAULT 'publish'
);

-- Inserting a post like WordPress does
INSERT INTO wp_posts (post_author, post_content, post_title, post_status) VALUES
(1, 'Hello World! This is our first post. 🌍', 'Hello World', 'publish');

-- Fetching posts
SELECT * FROM wp_posts;

-- WordPress loves its SQL queries! ⚙️`;

export default CodeBeams;
// TimelineItem.jsx
import React from 'react';
import { useInView } from 'react-intersection-observer';

const TimelineItem = ({ item, index }) => {
  const { title, subTitle, date, description, iconType } = item;

  const [ref, inView] = useInView({
    threshold: 0.5, // Adjust as needed
    triggerOnce: false,
  });

  const isEven = index % 2 === 0;

  return (
    <div
      ref={ref}
      className={`relative flex items-start md:items-center mb-6 md:mb-0 ${
        isEven ? 'md:flex-row-reverse' : 'md:flex-row'
      }`}
    >
      {/* Icon */}
      <div
        className={`absolute left-1px md:left-1/2 transform -translate-x-1/2 flex items-center justify-center w-10 h-10 rounded-full border border-white shadow ${
          inView ? 'bg-violet-600 text-white' : 'bg-slate-300 text-slate-500'
        }`}
        style={{ zIndex: 10 }}
      >
        {/* Icon SVG */}
        {iconType === 'briefcase' ? (
            <svg
            className="fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
          >
            <path d="M4 4V2.5A1.5 1.5 0 015.5 1h5A1.5 1.5 0 0112 2.5V4h1.5A1.5 1.5 0 0115 5.5v7A1.5 1.5 0 0113.5 14h-11A1.5 1.5 0 011 12.5v-7A1.5 1.5 0 012.5 4H4zm1 0h6V2.5a.5.5 0 00-.5-.5h-5a.5.5 0 00-.5.5V4z" />
          </svg>
        ) : (
          // Other icons if needed
          <svg
            className="fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
          >
            <path d="M12 10v2H7V8.496a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5V12H0V4.496a.5.5 0 01.206-.4l5.5-4a.5.5 0 01.588 0l5.5 4a.5.5 0 01.206.4V10Z" />
          </svg>
        )}
      </div>

      {/* Spacer for small screens */}
      <div className="hidden md:block w-1/2"></div>

      {/* Card */}
      <div
        className={`ml-16 md:ml-0 w-full md:w-1/2 bg-white p-8 rounded border border-slate-200 shadow ${
          isEven ? 'md:mr-8' : 'md:ml-8'
        }`}
        style={{ zIndex: 9 }}
      >
        <div className="flex items-center justify-between mb-1 space-x-2">
          <div>
            <div className="text-2xl font-bold text-slate-900">{title}</div>
            <div className="text-sm italic text-slate-500">{subTitle}</div>
          </div>
          <time className="font-caveat font-medium text-violet-600">
            {date}
          </time>
        </div>
        <div className="mt-4 text-slate-500">{description}</div>
      </div>
    </div>
  );
};

export default TimelineItem;
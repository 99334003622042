// CountUpStats.js
import React, { useEffect, useRef } from "react";
import { animate, useInView } from "framer-motion";

export const CountUpStats = () => {
  return (
    <div className="w-auto mt-10">
      <div className="flex flex-col items-start sm:flex-row sm:gap-8">
      <Stat
          num={10}
          suffix="+"
          subheading="Years of agency and in-house experience"
        />
        <div className="h-[1px] w-12 bg-indigo-200 sm:h-12 sm:w-[1px] relative -left-4 flex-none" />
        <Stat
          num={100}
          suffix="+"
          subheading="Successful Websites Launched"
        />
      </div>
    </div>
  );
};

const Stat = ({ num, suffix, decimals = 0, subheading }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  useEffect(() => {
    if (!isInView) return;

    const controls = animate(0, num, {
      duration: 1,
      onUpdate(value) {
        if (ref.current) {
          ref.current.textContent = value.toFixed(decimals);
        }
      },
    });

    return () => controls.stop();
  }, [num, decimals, isInView]);

  return (
    <div className="flex flex-col items-start py-8 sm:py-0 flex-none">
      <p className="mb-2 text-left text-3xl font-semibold sm:text-5xl text-white">
        <span ref={ref}></span>
        {suffix}
      </p>
      <p className="max-w-48 text-left text-gray-500">{subheading}</p>
    </div>
  );
};
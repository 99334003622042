import { motion } from "framer-motion";
import { useState } from "react";
import NeuFollowButton from './NeuFollowButton';

import {
  FiArrowUp,
  FiChevronLeft,
  FiChevronRight,
  FiLink,
  FiTarget,
  FiTool,
  FiShield
} from "react-icons/fi";

const CollapseCardFeatures = () => {
  const [position, setPosition] = useState(0);

  const shiftLeft = () => {
    if (position > 0) {
      setPosition((pv) => pv - 1);
    }
  };

  const shiftRight = () => {
    if (position < features.length - 1) {
      setPosition((pv) => pv + 1);
    }
  };

  return (
    <section className="overflow-hidden bg-neutral-100 py-40">
      <div className="mx-auto w-full max-w-7xl px-5 md:px-10">
        <div className="mb-8 flex justify-between gap-4">
          <h2 className="text-4xl font-bold leading-[1.2] md:text-5xl">
            Need something? <span className="text-neutral-400">Here's what I can do:</span>
          </h2>
          <div className="flex gap-2">
            <button
              className="h-fit bg-black p-4 text-2xl text-white transition-colors hover:bg-neutral-700"
              onClick={shiftLeft}
            >
              <FiChevronLeft />
            </button>
            <button
              className="h-fit bg-black p-4 text-2xl text-white transition-colors hover:bg-neutral-700"
              onClick={shiftRight}
            >
              <FiChevronRight />
            </button>
          </div>
        </div>
        <div className="flex gap-4">
          {features.map((feat, index) => (
            <Feature {...feat} key={index} position={position} index={index} />
          ))}
        </div>
        <div className="mt-12">
          <NeuFollowButton text="See my services!" link="/services" />
        </div>
      </div>
    </section>
  );
};

const Feature = ({ position, index, title, description, Icon }) => {
  const translateAmt =
    position >= index ? index * 100 : index * 100 - 100 * (index - position);

  return (
    <motion.div
      animate={{ x: `${-translateAmt}%` }}
      transition={{
        ease: "easeInOut",
        duration: 0.35,
      }}
      className={`relative flex min-h-[250px] w-10/12 max-w-lg shrink-0 flex-col justify-between overflow-hidden p-8 shadow-lg md:w-3/5 ${
        index % 2 ? "bg-black text-white" : " bg-white"
      }`}
    >
      <Icon className="absolute right-2 top-2 text-7xl opacity-20" />
      <h3 className="mb-8 text-3xl font-bold">{title}</h3>
      <p>{description}</p>
    </motion.div>
  );
};

export default CollapseCardFeatures;

const features = [
  {
    title: "Custom Web Development",
    Icon: FiTool,
    description:
      "Tailor-made websites and applications built to meet unique business needs, with a focus on WordPress, PHP, and modern JavaScript frameworks.",
  },
  {
    title: "SEO Optimization",
    Icon: FiTarget,
    description:
      "Enhance visibility and reach with in-depth SEO strategies, including on-page optimization, keyword targeting, and performance improvements.",
  },
  {
    title: "Data-Driven Insights",
    Icon: FiArrowUp,
    description:
      "Unlock actionable insights through advanced analytics, including server-side tagging, to make smarter, data-driven decisions that drive growth.",
  },
  {
    title: "GDPR & Cookie Compliance",
    Icon: FiShield,
    description:
      "Ensure compliance with GDPR and other data privacy regulations, including Cookiebot integration and best practices for data handling.",
  },
  {
    title: "Efficient Integrations",
    Icon: FiLink,
    description:
      "Streamline workflows with seamless integration of 3rd-party tools, from CRM systems to marketing platforms, enabling smooth operation.",
  },
];

